import Container from "../Container"
import styles from "./Banner.module.scss"
import Button from "../Button/Button"
import {useContext} from "react"
import {ModalContext} from "../../contexts/ModalContext"
import Features from "../Features/Features"

export default function Banner() {
	const {handleModal} = useContext(ModalContext)
	return (
		<section className={styles.root}>
			<picture>
				<source srcSet={"/images/banner.jpg"} media={"(min-width: 920px)"} width={1920} height={1032} />
				<img className={styles.image} src='/images/banner-m.jpg' alt='banner' width={860} height={1442} />
			</picture>
			<div className={styles.wrapper}>
				<Container noPaddingTop noPaddingBottom>
					<div className={styles.description}>
						<div className={styles.titleWrap}>
							<div className={styles.title}>OMODA C5</div>
							<div className={styles.text}>Новогодние ночи продаж, полный привод по цене переднего!</div>
						</div>
						<Features />
						<div className={styles.buttonWrap}>
							<Button
								title={"Заказать звонок"}
								fullwidth
								message={"Заявка с главного баннера"}
								type={"main"}
								onClick={() => handleModal("Забронируй новый OMODA C5", "Заявка из баннера C5", "C5")}
							>
								Забронировать
							</Button>
							<div className={styles.buttonText}>
								Улучшим любое предложение
								<br /> от других дилеров!
							</div>
						</div>
					</div>
				</Container>
			</div>
		</section>
	)
}
